<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="mr-5 ml-5 mt-6 mb-5" style="background: #f5f5f5">
              <v-card-title>
                <span class="headline float-right mr-2">Add Banner</span>
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <!-- <v-text-field
                    v-model="count"
                    :rules="countRules"
                    label="Count"
                    outlined
                    dense
                    @input="appendImage"
                  ></v-text-field> -->
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="set_time"
                    label="Set Time"
                    outlined
                    dense
                  ></v-text-field>
                </v-col> -->
                  </v-row>
                </v-form>
                ​
                <v-form>
                  <v-row v-for="(image, index) in custom.images" :key="index">
                    <v-col cols="4">
                      <v-text-field
                        v-model="image.link"
                        label="Link"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="image.content"
                        label="content "
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="d-flex flex-row">
                      <v-file-input
                        label="File input"
                        v-model="image.image"
                        outlined
                        dense
                      ></v-file-input>

                      <v-btn
                        class="ml-1"
                        fab
                        small
                        outlined
                        color="red"
                        @click="deleteItem(index)"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                      <v-btn
                        color="success"
                        class="mx-2"
                        fab
                        small
                        outlined
                        @click="appendImage"
                        v-if="custom.images.length == index + 1"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="close">Cancel</v-btn>
                <v-btn
                  color="success"
                  @click="save"
                  :disabled="!custom.images.length"
                  >ADD</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" centered :color="color">
      {{ text }}
      ​
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
​
​
<script>
export default {
  data: () => ({
    valid: false,
    count: 0,
    snackbar: false,
    countRules: [(v) => !!v || "Count is required"],
    set_time: "2000",
    custom: {
      images: [{ link: "", content: "", image: null }],
    },
    user: {},
  }),
  methods: {
    save() {
      const formData = new FormData();
      function buildFormData(formData, data, parentKey) {
        if (
          data &&
          typeof data === "object" &&
          !(data instanceof Date) &&
          !(data instanceof File)
        ) {
          Object.keys(data).forEach((key) => {
            buildFormData(
              formData,
              data[key],
              parentKey ? `${parentKey}[${key}]` : key
            );
          });
        } else {
          const value = data == null ? "" : data;
          formData.append(parentKey, value);
        }
      }
      buildFormData(formData, this.custom);
      formData.append("count", this.custom.images.length);
      formData.append("set_time", this.set_time);
      for (var pair of formData.entries()) {
        // console.log(pair[0] + ", " + pair[1]);
      }
      axios
        .post(`site/seller_banner_insert/${this.user.user_id}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          // console.log("here saved", response.data);
          this.text = response.data.message;
          this.color = "success";
          this.snackbar = true;
          this.$router.push("/merchantdashboard/pages/banner");
        });
    },
    close() {
      this.$router.push("/merchantdashboard/pages/banner");
    },
    appendImage() {
      let image = { link: "", content: "", image: null };
      this.custom.images.push(image);
    },
    deleteItem(index) {
      // console.log(index);
      this.custom.images.splice(index, 1);
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user.user_id) {
      this.initialize();
    }
  },
};
</script>
​
<style>
</style>